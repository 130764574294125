.btn{
	font-family: 'montserratsemibold';
}

.btn-expanded{
	padding-left: 1rem;
	padding-right: 1rem;

	&.btn-lg{
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

.btn-gradient-deepaqua{
	color: #FFF;
	background-color: $aqua;
	background-image: linear-gradient(166deg, $aqua, $marinho);
	overflow: hidden;
	position: relative;

	&:before{
		content:'';
		display: block;
		position: absolute;
		width: 50%;
		height: 100%;
		background: linear-gradient(to right, transparent, rgba(#FFF,0.8), transparent);
		transform: skew(-15deg);
		left:-60%;
		top: 0;
	}

	&:hover{
		color:#FFF;

		&:before{
			transition: left 0.6s linear;
			left:100%;
		}
	}
}

.btn-outline-dark{
	position: relative;

	&:before{
		content:'';
		display: block;
		position: absolute;
		width: 50%;
		height: 100%;
		background: linear-gradient(to right, transparent, rgba(#FFF,0.8), transparent);
		transform: skew(-15deg);
		left:-60%;
		top: 0;
	}

	&:hover{
		background-color: $aqua;
		background-image: linear-gradient(166deg, $aqua, $marinho);

		&:before{
			transition: left 0.6s linear;
			left:100%;
		}
	}
}

.btn-carregar-mais{
	padding: .75rem 1.5rem;
	border-width: 2px;
	border-color:#FFF;
	color: #FFF;
	border-radius: 6px;
	position: relative;
	perspective: 3px;
	transition: all .3s linear;
	overflow: hidden;

	&:before{
		content:'';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: #FFF;
		z-index: -1;
		clip-path: circle(0 at 50% 50%);
		transition: clip-path .3s linear;
	}

	&:hover{
		color: #000;

		&:before{
			clip-path: circle(100% at 50% 50%);
		}
	}
}

@import "menu";

.main-topo{
	@include media-breakpoint-down(sm) {
		width: 250px;
		height: 100%;
		position: fixed;
		left:-250px;
		top:0;
		z-index: 1000;
		background: $cinza;
		color: #FFF;
		overflow: auto;
		transition: left 0.6s linear;

		&.show{
			left:0;
		}

		.brand{
			display: none;
		}

		.menu{
			border-top:1px solid rgba(#FFF,.2);
		}
	}

	@include media-breakpoint-between(md, lg) {
		background: $cinza;

		.brand{
			order:1;
		}

		.minha-conta{
			order: 2;
		}

		& > .content{
			color: #FFF;
			padding-top: 10px;
			padding-bottom: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
		}
	}

	@include media-breakpoint-up(lg) {
		background: $cinza;

		& > .content{
			color: #FFF;
			padding-top: 10px;
			padding-bottom: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.brand{
				width: 190px;
			}
		}
	}
}

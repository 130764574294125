.menu{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	font-family: 'montserratsemibold', sans-serif;
	font-size: 15px;

	@include media-breakpoint-down(sm) {
		a{
			display: block;
			padding: 10px;
			position: relative;
			z-index: 2;

			&:before{
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				position: absolute;
				z-index:-1;
				background: linear-gradient(15deg, $aqua, $marinho);
				transition:all 0.3s linear;
				transition-delay: 0.3s;
				opacity: 0;
			}

			&:hover{
				text-decoration: none;
			}
		}

		li{transition: background 0.3s linear}

		.active,
		li:hover{
			background: $aqua;

			a{
				color: #FFf;
			}

			a:before{
				opacity: 1;
			}
		}
	}

	@include media-breakpoint-up(md) {
		a{
			display: block;
			padding: 15px 0;
			position: relative;

			&:after{
				content:'';
				position: absolute;
				width: 0;
				height: 6px;
				border-radius: 6px;
				background: linear-gradient(111deg, $aqua, $marinho);
				left:50%;
				bottom:0;
				transform: translateX(-50%);
				transition: width 0.3s linear;
			}

			&:hover{
				color: #FFF;
				text-decoration: none;
			}
		}

		.active a:after,
		a:hover:after{
			width: 60%;
		}
	}

	@include media-breakpoint-between(md, lg) {
		display: flex;
		justify-content: space-between;
		width: 100%;
		order:3;
		margin-top: 20px;

		a{
			display: block;
			padding: 10px 0;
		}
	}

	@include media-breakpoint-up (xl) {
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		padding: 0 30px;

	}

	@include media-breakpoint-up(lg) {
		font-size: 13px;
	}
}

.minha-conta{
	font-family: 'montserratsemibold', sans-serif;
	font-size: 15px;

	& > a{
		display: block;
		padding: 15px;
		background: $aqua;
		background-image: linear-gradient(111deg, $aqua, $marinho);
		color: #FFF;
		border-radius: 10px;

		&:hover{
			text-decoration: none;
		}
	}

	&.show > a{
		border-radius: 10px 10px 0 0;
	}

	.dropdown-menu{
		border-radius: 0 0 10px 10px;
		margin-top:-1px;
		padding:0;

		a{
			display: block;
			padding: 10px 15px;
			transition: background 0.3s linear;

			&:hover{
				text-decoration: none;
				color: #FFF;
				background: $aqua;
				// background-image: linear-gradient(45deg, $aqua, $marinho);
			}

			&:last-child{
				border-radius: 0 0 10px 10px;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		& > a,
		&.show > a{
			border-radius: 0 0 0 0;
		}

		.dropdown-menu{
			width: 100%;
			border-radius:0 0 0 0;
			position: relative !important;
			transform: none!important;
			float: none!important;

			& > a:last-child{
				border-radius:0 0 0 0;
			}
		}
	}
}

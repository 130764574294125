#alertas{
	position: fixed;
	z-index: 2000;
	max-width: calc(100vw - 30px);
	top: 90px;
	right: 15px;

	.alert{
		border-color: rgba(#000,.2);
		border-radius: 0;
		box-shadow: 0 0 8px rgba(#000,0.5);

		.close{
			font-size: 16px;
			margin-left: 10px;
		}

		.icon-area{
			margin-right: 10px;
		}
	}

	.alert-default{
		background: #FFF;
	}

	.alert-danger,
	.error{
		background: $danger;
		color: #FFF;
	}

	.alert-success,
	.success{
		background: $success;
		color: #FFF;
	}

	.alert-info,
	.info{
		background: $primary;
		color: #FFF;
	}
}

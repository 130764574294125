@import "mobile-controls";
@import "faixa-topo";
@import "main-topo";

@mixin topoSticky () {
	position: sticky;
	top:0;
	z-index: 997;
}

.topo{

	@include media-breakpoint-down (sm) {
		@include topoSticky();
	}

	@include media-breakpoint-up (md) {
		.content{
			padding-left: 15px;
			padding-right: 15px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include media-breakpoint-only (lg) {
		.content{
			max-width: map-get($container-max-widths, lg);
		}
	}

	@include media-breakpoint-up (xl) {
		@include topoSticky();
		.content{
			max-width: map-get($container-max-widths, xl);
		}
	}

}

.icon {
  background-image: url("../images/sprites.png");
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  background-size: calc(165px / 2) calc(108px / 2);
}

.icon.clound-up {
  width: calc(60px / 2);
  height: calc(56px / 2);
  background-position: calc(0px / 2) calc(0px / 2);
}

.icon.detalhe-depoimento-3 {
  width: calc(53px / 2);
  height: calc(42px / 2);
  background-position: calc(-70px / 2) calc(0px / 2);
}

.icon.favicon {
  width: calc(32px / 2);
  height: calc(32px / 2);
  background-position: calc(-133px / 2) calc(0px / 2);
}

.icon.megafone-agenda-1 {
  width: calc(19px / 2);
  height: calc(18px / 2);
  background-position: calc(-133px / 2) calc(-83px / 2);
}

.icon.stardust {
  width: calc(31px / 2);
  height: calc(31px / 2);
  background-position: calc(-133px / 2) calc(-42px / 2);
}

.icon.telemarketing-sm {
  width: calc(33px / 2);
  height: calc(34px / 2);
  background-position: calc(-50px / 2) calc(-66px / 2);
}

.icon.telemarketing {
  width: calc(40px / 2);
  height: calc(42px / 2);
  background-position: calc(0px / 2) calc(-66px / 2);
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#app .wrapper {
  flex-grow: 1;
}

.svg-icon,
.svg-sprite {
  width: 1em;
  height: 1em;
  min-width: 1em;
  min-height: 1em;
}

.svg-icon use,
.svg-sprite use {
  fill: currentcolor;
}

.mgb-30 {
  margin-bottom: 30px;
}

.mgb-40 {
  margin-bottom: 40px;
}

.mgb-50 {
  margin-bottom: 50px;
}

.mgb-60 {
  margin-bottom: 60px;
}

.embed-responsive {
  width: 100%;
  max-width: calc(var(--width) * 1px);
  max-height: calc(var(--height) * 1px);
}

.embed-responsive:before {
  content: '';
  display: block;
  padding-bottom: calc( (var(--height) / var(--width) ) * 100%);
}

.embed-responsive.lazy-embed {
  background: #DDD;
}

.embed-responsive.lazy-embed:not(.loaded):after {
  content: '';
  width: 30px;
  height: 30px;
  border: 3px solid #009aa7;
  border-radius: 50%;
  border-left-color: transparent;
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  animation: lazyEmbedLoading 1s linear infinite;
}

.embed-responsive.lazy-embed:not(.loaded) iframe {
  opacity: 0;
}

.embed-responsive.lazy-embed.loaded iframe {
  opacity: 1;
}

.embed-responsive.lazy-embed iframe {
  transition: opacity 1s linear;
}

@keyframes lazyEmbedLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.bg-gradient-aqua {
  background: linear-gradient(166deg, #009aa7, #1b5488);
  color: #FFF;
}

.modal .modal-content,
.modal .modal-body,
.modal .modal-footer,
.modal .modal-header {
  border-radius: 0;
}

.btn {
  font-family: 'montserratsemibold';
}

.btn-expanded, #videos-2 .card-video .btn-card {
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn-expanded.btn-lg, #videos-2 .card-video .btn-lg.btn-card {
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn-gradient-deepaqua, #produtos-mod-2 .produto .btn, #videos-2 .card-video .btn-card {
  color: #FFF;
  background-color: #009aa7;
  background-image: linear-gradient(166deg, #009aa7, #1b5488);
  overflow: hidden;
  position: relative;
}

.btn-gradient-deepaqua:before, #produtos-mod-2 .produto .btn:before, #videos-2 .card-video .btn-card:before {
  content: '';
  display: block;
  position: absolute;
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8), transparent);
  transform: skew(-15deg);
  left: -60%;
  top: 0;
}

.btn-gradient-deepaqua:hover, #produtos-mod-2 .produto .btn:hover, #videos-2 .card-video .btn-card:hover {
  color: #FFF;
}

.btn-gradient-deepaqua:hover:before, #produtos-mod-2 .produto .btn:hover:before, #videos-2 .card-video .btn-card:hover:before {
  transition: left 0.6s linear;
  left: 100%;
}

.btn-outline-dark {
  position: relative;
}

.btn-outline-dark:before {
  content: '';
  display: block;
  position: absolute;
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8), transparent);
  transform: skew(-15deg);
  left: -60%;
  top: 0;
}

.btn-outline-dark:hover {
  background-color: #009aa7;
  background-image: linear-gradient(166deg, #009aa7, #1b5488);
}

.btn-outline-dark:hover:before {
  transition: left 0.6s linear;
  left: 100%;
}

.btn-carregar-mais {
  padding: .75rem 1.5rem;
  border-width: 2px;
  border-color: #FFF;
  color: #FFF;
  border-radius: 6px;
  position: relative;
  perspective: 3px;
  transition: all .3s linear;
  overflow: hidden;
}

.btn-carregar-mais:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #FFF;
  z-index: -1;
  clip-path: circle(0 at 50% 50%);
  transition: clip-path .3s linear;
}

.btn-carregar-mais:hover {
  color: #000;
}

.btn-carregar-mais:hover:before {
  clip-path: circle(100% at 50% 50%);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

lazy-image {
  display: inline-block;
  width: 100%;
  max-width: calc(var(--w) * 1px);
  max-height: calc(var(--h) * 1px);
  position: relative;
  overflow: hidden;
}

lazy-image:before {
  content: '';
  padding-bottom: calc( (var(--h) / var(--w)) * 100%);
  display: block;
}

lazy-image:not([loaded]), lazy-image.clone:not(.clone-loaded) {
  background: #ddd;
}

lazy-image:not([loaded]):after, lazy-image.clone:not(.clone-loaded):after {
  content: '';
  width: 30px;
  height: 30px;
  border: 3px solid #009aa7;
  border-radius: 50%;
  border-left-color: transparent;
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  animation: lazyImageLoading 1s linear infinite;
}

lazy-image:not([loaded]) img, lazy-image.clone:not(.clone-loaded) img {
  opacity: 0;
}

lazy-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: opacity 0.4s linear;
}

@keyframes lazyImageLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

#alertas {
  position: fixed;
  z-index: 2000;
  max-width: calc(100vw - 30px);
  top: 90px;
  right: 15px;
}

#alertas .alert {
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

#alertas .alert .close {
  font-size: 16px;
  margin-left: 10px;
}

#alertas .alert .icon-area {
  margin-right: 10px;
}

#alertas .alert-default {
  background: #FFF;
}

#alertas .alert-danger,
#alertas .error {
  background: #dc3545;
  color: #FFF;
}

#alertas .alert-success,
#alertas .success {
  background: #28a745;
  color: #FFF;
}

#alertas .alert-info,
#alertas .info {
  background: #007bff;
  color: #FFF;
}

@font-face {
  font-family: 'montserratmedium';
  font-display: auto;
  src: url("../fonts/montserrat-medium/montserrat-medium-webfont.eot");
  src: url("../fonts/montserrat-medium/montserrat-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-medium/montserrat-medium-webfont.woff2") format("woff2"), url("../fonts/montserrat-medium/montserrat-medium-webfont.woff") format("woff"), url("../fonts/montserrat-medium/montserrat-medium-webfont.ttf") format("truetype"), url("../fonts/montserrat-medium/montserrat-medium-webfont.svg#montserratmedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserratsemibold';
  font-display: auto;
  src: url("../fonts/montserrat_semi_bold/montserrat-semi-bold-webfont.eot");
  src: url("../fonts/montserrat_semi_bold/montserrat-semi-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat_semi_bold/montserrat-semi-bold-webfont.woff2") format("woff2"), url("../fonts/montserrat_semi_bold/montserrat-semi-bold-webfont.woff") format("woff"), url("../fonts/montserrat_semi_bold/montserrat-semi-bold-webfont.ttf") format("truetype"), url("../fonts/montserrat_semi_bold/montserrat-semi-bold-webfont.svg#montserratsemibold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserratregular';
  font-display: auto;
  src: url("../fonts/montserrat_regular/montserrat-regular-webfont.eot");
  src: url("../fonts/montserrat_regular/montserrat-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat_regular/montserrat-regular-webfont.woff2") format("woff2"), url("../fonts/montserrat_regular/montserrat-regular-webfont.woff") format("woff"), url("../fonts/montserrat_regular/montserrat-regular-webfont.ttf") format("truetype"), url("../fonts/montserrat_regular/montserrat-regular-webfont.svg#montserratregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserratextrabold';
  font-display: auto;
  src: url("../fonts/montserrat-extra-bold/montserrat-extra-bold-webfont.eot");
  src: url("../fonts/montserrat-extra-bold/montserrat-extra-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-extra-bold/montserrat-extra-bold-webfont.woff2") format("woff2"), url("../fonts/montserrat-extra-bold/montserrat-extra-bold-webfont.woff") format("woff"), url("../fonts/montserrat-extra-bold/montserrat-extra-bold-webfont.ttf") format("truetype"), url("../fonts/montserrat-extra-bold/montserrat-extra-bold-webfont.svg#montserratextrabold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserratblack';
  font-display: auto;
  src: url("../fonts/montserrat-black/montserrat-black-webfont.eot");
  src: url("../fonts/montserrat-black/montserrat-black-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-black/montserrat-black-webfont.woff2") format("woff2"), url("../fonts/montserrat-black/montserrat-black-webfont.woff") format("woff"), url("../fonts/montserrat-black/montserrat-black-webfont.ttf") format("truetype"), url("../fonts/montserrat-black/montserrat-black-webfont.svg#montserratblack") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'montserratregular', sans-serif;
}

a {
  color: inherit;
}

.text-aqua {
  color: #009aa7 !important;
}

a.text-aqua:hover, a.text-aqua:focus {
  color: #00535b !important;
}

.text-marinho {
  color: #1b5488 !important;
}

a.text-marinho:hover, a.text-marinho:focus {
  color: #0e2d48 !important;
}

.subtitle {
  font-family: 'montserratsemibold';
  margin-bottom: 20px;
  font-size: 20px;
}

.content__editable {
  font-size: 15px;
  color: #282828;
}

.content__editable::after {
  display: block;
  clear: both;
  content: "";
}

.content__editable h1,
.content__editable h2,
.content__editable h3,
.content__editable h4,
.content__editable h5,
.content__editable h6 {
  font-family: 'montserratsemibold';
}

.content__editable img {
  max-width: 100%;
  height: auto !important;
}

.mobile-controls {
  background: #282828;
  display: flex;
  justify-content: space-between;
}

.mobile-controls .brand {
  padding: 5px 10px;
  width: 215px;
}

@media (min-width: 768px) {
  .mobile-controls {
    display: none;
  }
}

.btn-mobile {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
  display: flex;
  align-items: center;
  border-radius: 0;
}

.btn-mobile:hover {
  color: #fff;
  background-color: #151515;
  border-color: #0f0f0f;
}

.btn-mobile:focus, .btn-mobile.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 72, 72, 0.5);
}

.btn-mobile.disabled, .btn-mobile:disabled {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}

.btn-mobile:not(:disabled):not(.disabled):active, .btn-mobile:not(:disabled):not(.disabled).active,
.show > .btn-mobile.dropdown-toggle {
  color: #fff;
  background-color: #0f0f0f;
  border-color: #080808;
}

.btn-mobile:not(:disabled):not(.disabled):active:focus, .btn-mobile:not(:disabled):not(.disabled).active:focus,
.show > .btn-mobile.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 72, 72, 0.5);
}

.btn-mobile .bars {
  width: 30px;
  height: 5px;
  background: currentcolor;
  border-radius: 5px;
  position: relative;
  point-events: none;
  transition: background 0.3s linear;
}

.btn-mobile .bars:before, .btn-mobile .bars:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  left: 0;
  transition: all 0.3s linear;
  background: currentcolor;
}

.btn-mobile .bars:before {
  top: -10px;
}

.btn-mobile .bars:after {
  top: 10px;
}

.btn-mobile.active .bars {
  background: transparent;
}

.btn-mobile.active .bars:before, .btn-mobile.active .bars:after {
  top: 0;
}

.btn-mobile.active .bars:before {
  transform: rotate(45deg);
}

.btn-mobile.active .bars:after {
  transform: rotate(-45deg);
}

.menu-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  z-index: 999;
  display: none;
}

.faixa-topo .svg-icon {
  font-size: 1.42857em;
}

.faixa-topo .boas-vindas {
  font-family: 'montserratmedium';
}

.faixa-topo .contatos {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  white-space: nowrap;
}

.faixa-topo .contatos li {
  display: flex;
  align-items: center;
}

.faixa-topo .contatos li svg {
  margin-right: 5px;
}

@media (max-width: 767.98px) {
  .faixa-topo .boas-vindas {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .faixa-topo .contatos li {
    padding: 5px 10px;
  }
  .faixa-topo .contatos li a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 768px) {
  .faixa-topo {
    font-size: 14px;
    background: #009aa7;
    color: #FFF;
  }
  .faixa-topo .content {
    display: flex;
    justify-content: space-between;
  }
  .faixa-topo .boas-vindas {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .faixa-topo .contatos {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .faixa-topo .contatos li {
    padding-left: 10px;
  }
  .faixa-topo .contatos li:not(:last-child) {
    padding-right: 10px;
  }
  .faixa-topo .contatos li + li {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  font-family: 'montserratsemibold', sans-serif;
  font-size: 15px;
}

@media (max-width: 767.98px) {
  .menu a {
    display: block;
    padding: 10px;
    position: relative;
    z-index: 2;
  }
  .menu a:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
    background: linear-gradient(15deg, #009aa7, #1b5488);
    transition: all 0.3s linear;
    transition-delay: 0.3s;
    opacity: 0;
  }
  .menu a:hover {
    text-decoration: none;
  }
  .menu li {
    transition: background 0.3s linear;
  }
  .menu .active,
  .menu li:hover {
    background: #009aa7;
  }
  .menu .active a,
  .menu li:hover a {
    color: #FFf;
  }
  .menu .active a:before,
  .menu li:hover a:before {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .menu a {
    display: block;
    padding: 15px 0;
    position: relative;
  }
  .menu a:after {
    content: '';
    position: absolute;
    width: 0;
    height: 6px;
    border-radius: 6px;
    background: linear-gradient(111deg, #009aa7, #1b5488);
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    transition: width 0.3s linear;
  }
  .menu a:hover {
    color: #FFF;
    text-decoration: none;
  }
  .menu .active a:after,
  .menu a:hover:after {
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    order: 3;
    margin-top: 20px;
  }
  .menu a {
    display: block;
    padding: 10px 0;
  }
}

@media (min-width: 1200px) {
  .menu {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    padding: 0 30px;
  }
}

@media (min-width: 992px) {
  .menu {
    font-size: 13px;
  }
}

.minha-conta {
  font-family: 'montserratsemibold', sans-serif;
  font-size: 15px;
}

.minha-conta > a {
  display: block;
  padding: 15px;
  background: #009aa7;
  background-image: linear-gradient(111deg, #009aa7, #1b5488);
  color: #FFF;
  border-radius: 10px;
}

.minha-conta > a:hover {
  text-decoration: none;
}

.minha-conta.show > a {
  border-radius: 10px 10px 0 0;
}

.minha-conta .dropdown-menu {
  border-radius: 0 0 10px 10px;
  margin-top: -1px;
  padding: 0;
}

.minha-conta .dropdown-menu a {
  display: block;
  padding: 10px 15px;
  transition: background 0.3s linear;
}

.minha-conta .dropdown-menu a:hover {
  text-decoration: none;
  color: #FFF;
  background: #009aa7;
}

.minha-conta .dropdown-menu a:last-child {
  border-radius: 0 0 10px 10px;
}

@media (max-width: 767.98px) {
  .minha-conta > a,
  .minha-conta.show > a {
    border-radius: 0 0 0 0;
  }
  .minha-conta .dropdown-menu {
    width: 100%;
    border-radius: 0 0 0 0;
    position: relative !important;
    transform: none !important;
    float: none !important;
  }
  .minha-conta .dropdown-menu > a:last-child {
    border-radius: 0 0 0 0;
  }
}

@media (max-width: 767.98px) {
  .main-topo {
    width: 250px;
    height: 100%;
    position: fixed;
    left: -250px;
    top: 0;
    z-index: 1000;
    background: #282828;
    color: #FFF;
    overflow: auto;
    transition: left 0.6s linear;
  }
  .main-topo.show {
    left: 0;
  }
  .main-topo .brand {
    display: none;
  }
  .main-topo .menu {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .main-topo {
    background: #282828;
  }
  .main-topo .brand {
    order: 1;
  }
  .main-topo .minha-conta {
    order: 2;
  }
  .main-topo > .content {
    color: #FFF;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .main-topo {
    background: #282828;
  }
  .main-topo > .content {
    color: #FFF;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main-topo > .content .brand {
    width: 190px;
  }
}

@media (max-width: 767.98px) {
  .topo {
    position: sticky;
    top: 0;
    z-index: 997;
  }
}

@media (min-width: 768px) {
  .topo .content {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .topo .content {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .topo {
    position: sticky;
    top: 0;
    z-index: 997;
  }
  .topo .content {
    max-width: 1140px;
  }
}

.rodape {
  background-color: #282828;
  background-image: linear-gradient(to bottom, #282828 0%, #100f10 calc(100% - 60px));
  color: #FFF;
  padding-top: 35px;
  padding-bottom: 15px;
}

@media (max-width: 767.98px) {
  .rodape {
    padding-bottom: 65px;
  }
}

@media (max-width: 575.98px) {
  .rodape {
    font-size: 14px;
  }
}

.rodape .brand {
  max-width: 255px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-style: italic;
  font-family: 'montserratregular';
  margin-bottom: 30px;
}

.rodape .brand img {
  margin-bottom: 10px;
}

.rodape a:hover {
  color: #009aa7;
  text-decoration: none;
}

.contatos-footer svg {
  color: #009aa7;
  font-size: 1.5em;
  margin-right: 10px;
}

.contatos-footer .telefones {
  display: flex;
}

.contatos-footer .telefones ul {
  display: inline-block;
  list-style: none;
  padding-left: 0;
  columns: 2;
  column-rule: 1px solid currentcolor;
  white-space: nowrap;
}

@media (max-width: 991.98px) {
  .contatos-footer .redes-sociais {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .contatos-footer .redes-sociais {
    display: flex;
    align-items: center;
  }
  .contatos-footer .redes-sociais .title {
    white-space: nowrap;
    margin-right: 10px;
  }
}

.contatos-footer .redes-sociais a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background: #009aa7;
  color: #282828;
  transition: background 0.3s linear;
}

.contatos-footer .redes-sociais a:hover {
  background: #FFF;
  text-decoration: none;
  color: #282828;
}

.creditos {
  text-align: center;
  font-size: 10px;
}

.autor {
  text-align: center;
  font-size: 10px;
}

.btns-fixos {
  position: fixed;
  bottom: 0;
  z-index: 2000;
  width: 100%;
  max-width: 1140px;
  left: 50%;
  transform: translateX(-50%);
}

.btns-fixos .btn {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  border-color: rgba(0, 0, 0, 0.3);
}

.btns-fixos .btn:before {
  content: '';
  width: 50%;
  height: 100%;
  left: -60%;
  top: 0;
  background: linear-gradient(to right, transparent, #FFF, transparent);
  opacity: 0.6;
  position: absolute;
  transform: skew(-15deg);
  animation: shiningButton 5s linear infinite;
}

.btn-cadastre-se {
  font-family: 'montserratmedium';
  color: #fff;
  background-color: #1b5488;
  border-color: #1b5488;
  left: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.btn-cadastre-se:hover {
  color: #fff;
  background-color: #154068;
  border-color: #133a5d;
}

.btn-cadastre-se:focus, .btn-cadastre-se.focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 110, 154, 0.5);
}

.btn-cadastre-se.disabled, .btn-cadastre-se:disabled {
  color: #fff;
  background-color: #1b5488;
  border-color: #1b5488;
}

.btn-cadastre-se:not(:disabled):not(.disabled):active, .btn-cadastre-se:not(:disabled):not(.disabled).active,
.show > .btn-cadastre-se.dropdown-toggle {
  color: #fff;
  background-color: #133a5d;
  border-color: #103353;
}

.btn-cadastre-se:not(:disabled):not(.disabled):active:focus, .btn-cadastre-se:not(:disabled):not(.disabled).active:focus,
.show > .btn-cadastre-se.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 110, 154, 0.5);
}

@media (min-width: 992px) {
  .btn-cadastre-se {
    border-radius: 10px 10px 0 0;
    bottom: 0;
    left: 15px;
  }
}

@media (max-width: 991.98px) {
  .btn-cadastre-se {
    margin-bottom: 10px;
    padding: 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .btn-cadastre-se span {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

.btn-teste {
  font-family: 'montserratmedium';
  color: #fff;
  background-color: #009aa7;
  border-color: #009aa7;
  right: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  max-width: 300px;
  text-align: left;
  display: flex;
}

.btn-teste:hover {
  color: #fff;
  background-color: #007781;
  border-color: #006b74;
}

.btn-teste:focus, .btn-teste.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 169, 180, 0.5);
}

.btn-teste.disabled, .btn-teste:disabled {
  color: #fff;
  background-color: #009aa7;
  border-color: #009aa7;
}

.btn-teste:not(:disabled):not(.disabled):active, .btn-teste:not(:disabled):not(.disabled).active,
.show > .btn-teste.dropdown-toggle {
  color: #fff;
  background-color: #006b74;
  border-color: #005f67;
}

.btn-teste:not(:disabled):not(.disabled):active:focus, .btn-teste:not(:disabled):not(.disabled).active:focus,
.show > .btn-teste.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 169, 180, 0.5);
}

.btn-teste svg {
  font-size: 37px;
}

@media (min-width: 992px) {
  .btn-teste {
    border-radius: 10px 10px 0 0;
    bottom: 0;
  }
  .btn-teste svg {
    margin-right: 15px;
    align-self: center;
  }
}

@media (max-width: 991.98px) {
  .btn-teste {
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
    padding: 0;
  }
  .btn-teste span {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .btn-teste:before {
    width: 80%;
  }
  .btn-teste svg {
    font-size: 20px;
  }
}

@keyframes shiningButton {
  0% {
    left: -60%;
  }
  12%, 100% {
    left: 100%;
  }
}

#banner {
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

#banner .carousel-inner {
  position: relative;
  z-index: 0;
}

#banner lazy-image {
  display: block;
}

#banner .carousel-indicators li {
  width: 14px;
  height: 14px;
  border: 2px solid #009aa7;
  border-radius: 50%;
  background: transparent;
  opacity: 1;
}

#banner .carousel-indicators li.active {
  background: #009aa7;
}

#banner .ctrls {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1140px;
  padding-left: 15px;
  padding-left: 15px;
}

#banner .ctrls a {
  color: #009aa7;
  width: 2em;
  height: 2em;
  border: 2px solid #009aa7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s linear;
}

#banner .ctrls a:hover {
  text-decoration: none;
  background: #009aa7;
  color: #FFF;
}

#banner .ctrls a.prev {
  left: 15px;
}

#banner .ctrls a.next {
  right: 15px;
}

.solucoes-index {
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
}

.solucoes-index .title {
  font-size: 36px;
  color: #282828;
  margin-bottom: 30px;
}

.solucoes-index .title span {
  font-family: 'montserratextrabold', sans-serif;
  color: #009aa7;
}

.solucoes-index .subtitle {
  font-size: 20px;
}

.solucoes-index .subtitle span {
  font-family: 'montserratextrabold', sans-serif;
}

@media (min-width: 1200px) {
  .solucoes-index {
    min-height: 915px;
    background-image: var(--lazyBG);
    background-position: calc(50% + 367px) bottom;
    background-repeat: no-repeat;
  }
}

@media (max-width: 767.98px) {
  .solucoes-index {
    text-align: center;
  }
  .solucoes-index:after {
    content: '';
    display: block;
    max-width: 875px;
    padding-bottom: 119.04762%;
    background-image: var(--lazyBG);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: calc(100% - 30px) auto;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .solucoes-index:after {
    content: '';
    display: block;
    width: 735px;
    height: 875px;
    background-image: var(--lazyBG);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: calc(100% - 30px) 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.servico-index {
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
}

.servico-index .icon-area {
  font-size: 64px;
  margin-bottom: 30px;
  transition: color 0.3s linear;
}

.servico-index .icon-area svg {
  display: block;
  margin: auto;
}

.servico-index .icon-area circle {
  fill: #009aa7;
  transition: fill 0.3s linear;
}

.servico-index .nome-servico {
  font-size: 15px;
  font-family: 'montserratsemibold', sans-serif;
  transition: color 0.3s linear;
}

.servico-index .descricao {
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.servico-index .ellipsis-h {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  color: #d1d1d1;
  background: currentcolor;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  transition: color 0.3s linear;
}

.servico-index .ellipsis-h:after, .servico-index .ellipsis-h:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  background: currentcolor;
}

.servico-index .ellipsis-h:before {
  right: 200%;
}

.servico-index .ellipsis-h:after {
  left: 200%;
}

.servico-index a:hover {
  text-decoration: none;
  color: inherit;
}

.servico-index a:hover .icon-area {
  color: #009aa7;
}

.servico-index a:hover .icon-area circle {
  fill: #1b5488;
}

.servico-index a:hover .nome-servico,
.servico-index a:hover .ellipsis-h {
  color: #009aa7;
}

.planos {
  background: #282828;
  color: #FFF;
  padding-top: 45px;
  padding-bottom: 45px;
}

.planos .h1 {
  font-family: 'montserratregular', sans-serif;
  font-size: 30px;
  text-align: center;
  margin-bottom: 1em;
}

.planos .h1 span {
  color: #009aa7;
  font-family: 'montserratextrabold', sans-serif;
}

@media (min-width: 576px) {
  .planos .plano__comparacao {
    display: flex;
  }
}

.planos .plano__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 80px;
}

@media (min-width: 576px) {
  .planos .plano__item {
    width: 50%;
    border-bottom: 1px solid #FFF;
  }
  .planos .plano__item + .plano__item {
    border-left: 1px solid #FFF;
  }
}

@media (max-width: 575.98px) {
  .planos .plano__item ~ .plano__item {
    border-top: 1px solid #FFF;
  }
}

@media (min-width: 992px) {
  .planos .plano__item {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 991.98px) {
  .planos .plano__item {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.planos .plano__header {
  padding: .75rem 1.5rem;
  border: 2px solid #FFF;
  border-radius: 7px;
  margin-bottom: 30px;
}

.planos .plano__features {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  width: 100%;
  max-width: 425px;
  font-size: 15px;
}

.planos .plano__features li {
  padding-left: 35px;
  min-height: 30px;
  position: relative;
  margin-bottom: 5px;
  padding-top: 3px;
}

.planos .plano__features li:before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  border: 2px solid #FFF;
  left: 0;
  top: 2.5px;
  border-radius: 50%;
}

.planos .plano__features li.active::before {
  background: #FFF;
  box-shadow: 0 0 0 3px #282828 inset;
}

@media (min-width: 576px) {
  .planos .fontes__disponiveis {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(auto, auto);
  }
}

@media (max-width: 575.98px) {
  .planos .fontes__disponiveis {
    display: flex;
    flex-direction: column;
    border-top: 2px solid #FFF;
  }
}

.planos .fontes__disponiveis__header {
  grid-column: 1/3;
  grid-row: 1/2;
  margin: auto;
  padding: .75rem 1.5rem;
  border: 2px solid #FFF;
  border-radius: 4px;
  background: #282828;
  transform: translateY(-50%);
  position: relative;
}

@media (min-width: 576px) {
  .planos .fontes__disponiveis__header:before {
    content: '';
    height: 100%;
    border-left: 1px solid #FFF;
    left: 50%;
    top: 100%;
    position: absolute;
  }
}

.planos .fontes__disponiveis__item {
  padding: 30px 20px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

@media (min-width: 576px) {
  .planos .fontes__disponiveis__item ~ .fontes__disponiveis__item {
    border-left: 1px solid #FFF;
  }
}

@media (max-width: 575.98px) {
  .planos .fontes__disponiveis__item {
    padding: 30px 0;
  }
  .planos .fontes__disponiveis__item ~ .fontes__disponiveis__item {
    border-top: 1px solid #FFF;
  }
}

.planos .fontes__disponiveis__item li {
  padding: 10px;
}

.nav-planos {
  list-style: none;
  padding-left: 0;
  margin-bottom: 30px;
  width: 100%;
  overflow: auto;
  display: flex;
}

.nav-planos a {
  display: block;
  padding: 0.325rem 0.725rem;
  border: 1px solid #FFF;
  border-radius: 6px;
  font-family: 'montserratmedium', sans-serif;
  transition: all 0.3s linear;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
}

.nav-planos a:before {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  background: #FFF;
  z-index: -2;
  transition: width 0.3s linear;
  left: 0;
  bottom: 0;
}

.nav-planos a:hover {
  text-decoration: none;
  color: #282828;
}

.nav-planos a:hover:before {
  width: 100%;
}

.nav-planos .active a {
  background: linear-gradient(166deg, #009aa7, #1b5488);
  border-color: transparent;
}

.nav-planos .active a:hover {
  color: #FFF;
}

.nav-planos .active a:before {
  display: none;
}

@media (min-width: 768px) {
  .nav-planos {
    justify-content: space-around;
  }
}

@media (max-width: 767.98px) {
  .nav-planos {
    justify-content: flex-start;
  }
  .nav-planos a {
    white-space: nowrap;
  }
  .nav-planos li + li {
    margin-left: 10px;
  }
}

.item-plano + .item-plano {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 767.98px) {
  .planos-container {
    padding-left: 15px;
  }
}

.header-item-plano {
  display: flex;
}

.header-item-plano .item-name {
  padding: 10px 10px 10px 0;
  width: calc(100% - 24px);
  order: 1;
  font-family: 'montserratblack', sans-serif;
  color: #009aa7;
  position: relative;
  transition: color 0.3s linear;
}

.header-item-plano .item-name:before {
  content: '';
  width: 1em;
  height: 1em;
  border: 2px solid #282828;
  background: currentcolor;
  position: absolute;
  left: -15px;
  top: 10px;
  transform: translate(-50%, 0.25em);
  border-radius: 50%;
}

.header-item-plano button {
  background: transparent;
  border: 1px solid #FFF;
  color: #FFF;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  order: 2;
  align-self: center;
  margin-left: auto;
  outline: none;
}

.header-item-plano button[aria-expanded="true"] .fa-plus:before {
  content: "\f068";
}

.header-item-plano button[aria-expanded="true"] ~ .item-name {
  color: #1b5488;
}

.section-parceiros {
  padding-top: 40px;
}

.section-parceiros h2 span {
  font-family: 'montserratextrabold', sans-serif;
  color: #009aa7;
}

.owl-carousel#parceiros {
  padding-left: calc(2em + 8px);
  padding-right: calc(2em + 8px);
}

.owl-carousel#parceiros .parceiro {
  padding: 8px;
  max-width: 160px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.owl-carousel#parceiros .parceiro:hover lazy-image {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.owl-carousel#parceiros .owl-prev,
.owl-carousel#parceiros .owl-next {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
}

.owl-carousel#parceiros .owl-prev:not(.disabled),
.owl-carousel#parceiros .owl-next:not(.disabled) {
  color: #009aa7;
  border: 2px solid #009aa7;
}

.owl-carousel#parceiros .owl-prev.disabled,
.owl-carousel#parceiros .owl-next.disabled {
  color: #999;
  border: 2px solid #999;
  cursor: default;
}

.owl-carousel#parceiros .owl-prev {
  left: 0;
}

.owl-carousel#parceiros .owl-next {
  right: 0;
}

.facilidades {
  padding-top: 60px;
  background-color: #1b5488;
  background-image: var(--lazyBG);
  background-position: center center;
  position: relative;
  padding-bottom: 30px;
  min-height: 210px;
  z-index: 0;
  color: #FFF;
}

.facilidades h2.title span {
  font-family: 'montserratsemibold', sans-serif;
  color: #009aa7;
}

.facilidades:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 120px;
  background: #282828;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.facilidades .container {
  position: relative;
  z-index: 1;
}

.facilidades .embed-responsive {
  margin-left: auto;
  margin-right: auto;
}

.internas {
  padding-bottom: 110px;
}

.page-header {
  background-color: #282828;
  color: #FFF;
  background-image: var(--lazyBG);
  background-position: center top;
  background-repeat: no-repeat;
  text-align: center;
  margin-bottom: 125px;
}

.page-header .filtro {
  min-height: 386px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(to bottom, rgba(40, 40, 40, 0.2) 0%, rgba(40, 40, 40, 0.9) calc(100% - 140px), #000 calc(100% - 20px));
  padding-bottom: 60px;
}

.page-header h1,
.page-header h2 {
  font-size: 35px;
}

.page-header h2 {
  font-family: 'montserratblack';
}

.page-header .breadcrumb {
  padding: 0 30px 15px 30px;
  min-width: 250px;
  max-width: 100%;
  background: none;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 0;
  border-radius: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  position: relative;
}

.page-header .breadcrumb span {
  color: #888;
  cursor: default;
}

.page-header .breadcrumb:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
  height: 5px;
  width: 60px;
  background: linear-gradient(166deg, #009aa7, #1b5488);
  border-radius: 5px;
}

.page-header .breadcrumb li + li:before {
  content: '/';
  display: inline-block;
  margin: 0 10px;
}

fieldset {
  margin-bottom: 40px;
}

fieldset legend h2 {
  font-size: 20px;
  color: #009aa7;
}

.form-control {
  border-color: #282828;
  color: #282828;
}

.md-input {
  position: relative;
  margin-top: 8px;
}

.md-input label {
  display: block;
  margin-bottom: 0;
  padding: 0 0.375rem;
  background: #FFF;
  left: 0.375rem;
  top: 0.4rem;
  position: absolute;
  line-height: 1.5rem;
  transition: all 0.3s linear;
}

.md-input.focus label,
.md-input .form-control:focus ~ label,
.md-input select.form-control ~ label {
  top: 0;
  transform: translateY(-50%);
  font-size: 12px;
}

.md-input.has-success .form-control {
  border-color: #28a745;
  color: #28a745;
}

.md-input.has-success label {
  color: #28a745;
}

.md-input.has-error .form-control {
  border-color: #dc3545;
  color: #dc3545;
}

.md-input.has-error label {
  color: #dc3545;
}

.paginacao {
  margin-bottom: 60px;
  text-align: center;
  color: #282828;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.paginacao > * {
  display: inline-block;
  padding: 0.3em 0.6em;
  border: 2px solid #282828;
}

.paginacao > *:first-child {
  border-radius: 4px 0 0 4px;
}

.paginacao > *:last-child {
  border-radius: 0 4px 4px 0;
}

.paginacao > * + * {
  margin-left: -2px;
}

.paginacao .active {
  background: linear-gradient(166deg, #009aa7, #1b5488);
  border-color: rgba(0, 0, 0, 0.2);
  color: #FFF;
  position: relative;
  z-index: 2;
}

.paginacao a:hover {
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.paginacao a:hover:hover {
  background: #282828;
  color: #FFF;
}

@media (min-width: 992px) {
  #qm-somos-1 .title-sobre {
    width: 540px;
    max-width: 50%;
  }
}

#qm-somos-1 .tragetoria {
  background: #F6F6F6;
  padding: 60px 0;
  margin-top: 60px;
}

#qm-somos-1 .title-tragetoria {
  text-align: center;
  font-size: 50px;
}

@media (max-width: 767.98px) {
  #qm-somos-1 .title-tragetoria {
    font-size: 30px;
  }
}

#qm-somos-1 .title-tragetoria span {
  display: block;
  color: #282828;
  font-size: 20px;
}

#qm-somos-1 .title-tragetoria .icon {
  position: relative;
}

#qm-somos-1 .title-tragetoria .icon:before, #qm-somos-1 .title-tragetoria .icon:after {
  content: '';
  width: 85px;
  position: absolute;
  top: calc(50% - 0.5px);
  border-top: 1px dashed currentcolor;
}

#qm-somos-1 .title-tragetoria .icon:before {
  right: 100%;
  margin-right: 10px;
}

#qm-somos-1 .title-tragetoria .icon:after {
  left: 100%;
  margin-left: 10px;
}

#qm-somos-1 .trajeto {
  text-align: center;
}

#qm-somos-1 .trajeto-title {
  font-size: 16px;
  margin-bottom: 10px;
  position: relative;
  padding: 0 10px;
}

#qm-somos-1 .trajeto-title:before, #qm-somos-1 .trajeto-title:after,
#qm-somos-1 .trajeto-title .detail:before,
#qm-somos-1 .trajeto-title .detail:after {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  position: absolute;
}

#qm-somos-1 .trajeto-title:before, #qm-somos-1 .trajeto-title:after {
  left: 0;
}

#qm-somos-1 .trajeto-title .detail:before,
#qm-somos-1 .trajeto-title .detail:after {
  right: 0;
}

#qm-somos-1 .trajeto-title:before,
#qm-somos-1 .trajeto-title .detail:before {
  background: #009aa7;
  top: 0.3em;
}

#qm-somos-1 .trajeto-title:after,
#qm-somos-1 .trajeto-title .detail:after {
  background: currentcolor;
  bottom: 0.3em;
}

#qm-somos-1 .trajeto-content {
  color: #888888;
}

#qm-somos-1 .missao-visao-valores {
  text-align: center;
}

#qm-somos-1 .missao-visao-valores .block lazy-image,
#qm-somos-1 .missao-visao-valores .block .lazyimage {
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
}

#qm-somos-1 .missao-visao-valores .block .title {
  margin-bottom: 10px;
  color: #009aa7;
  font-size: 20px;
}

#qm-somos-1 .missao-visao-valores .block .title span {
  font-size: 2em;
}

#produtos-mod-2 {
  color: #777;
}

#produtos-mod-2 .subtitle-produtos {
  font-weight: bold;
  font-size: 24px;
  color: #000;
}

#produtos-mod-2 .subtitle-produtos svg {
  color: #009aa7;
  margin: 0;
  vertical-align: middle;
}

#produtos-mod-2 .row-produtos > * {
  display: flex;
}

#produtos-mod-2 .produto {
  width: 100%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

#produtos-mod-2 .produto .foto {
  margin-bottom: 0;
}

#produtos-mod-2 .produto .nome {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}

#produtos-mod-2 .produto .descricao {
  color: #777;
  padding-bottom: 10px;
}

#produtos-mod-2 #carousel {
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
}

#produtos-mod-2 #carousel figure {
  margin-bottom: 0;
}

#produtos-mod-2 #carousel .controls {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #009aa7;
  display: flex;
  font-size: 20px;
}

#produtos-mod-2 #carousel .controls:before {
  content: '';
  width: 25px;
  height: 100%;
  position: absolute;
  right: calc(100% - 4px);
  top: 0;
  background: #009aa7;
  clip-path: polygon(0 100%, 20px 0, 100% 0, 100% 100%);
}

#produtos-mod-2 #carousel .controls a {
  display: block;
  padding: 10px;
  color: #FFF;
}

#produtos-mod-2 #carousel .controls a:hover {
  color: #ffc107;
  background: rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

#produtos-mod-2 #carousel lazy-image {
  display: block;
}

#clientes-e-parceiros-mod-1 .row.clientes {
  align-items: center;
}

#clientes-e-parceiros-mod-1 .row.clientes > * {
  margin-bottom: 30px;
}

#clientes-e-parceiros-mod-1 .row.clientes img {
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+, Safari 6+, Safari 6+ iOS */
  transition: all 0.3s linear;
}

#clientes-e-parceiros-mod-1 .row.clientes img:hover {
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
  -webkit-filter: grayscale(0%);
}

#clientes-e-parceiros-mod-1 .paginacao .active {
  background: #FFB554;
  color: #000;
}

#clientes-e-parceiros-mod-1 .titles {
  color: #009aa7;
}

#clientes-e-parceiros-mod-1 .titles:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 0.2em;
  margin-bottom: 0.2em;
  background: currentcolor;
  margin-right: 0.5em;
}

#fontes .cliente {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 12px solid rgba(0, 0, 0, 0.1);
}

#fontes .cliente lazy-image {
  display: block;
}

@media (max-width: 575.98px) {
  #fontes .cliente lazy-image {
    margin-right: auto;
    margin-left: auto;
  }
}

#fontes .cliente figure {
  margin-bottom: 10px;
}

#fontes .cliente img {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #FFF;
  padding: 10px;
}

#fontes .cliente .nome {
  text-align: left;
  margin-top: 0;
  overflow: hidden;
  margin-bottom: 15px;
  color: #009aa7;
}

#fontes .cliente .nome span {
  display: inline-block;
  position: relative;
}

#fontes .cliente .nome span:before {
  content: '';
  display: block;
  border: 1px solid #009aa7;
  left: 100%;
  margin-left: 30px;
  top: calc(50% - 1px);
  width: 100vw;
  position: absolute;
}

#fontes .cliente .nome:after {
  content: '';
  background: currentcolor;
  opacity: 0.9;
  display: block;
  width: 2em;
  height: 4px;
  margin-top: 5px;
  border-radius: 4px;
}

#videos-2 .titles {
  overflow: hidden;
  margin-bottom: 60px;
}

#videos-2 .titles h2 {
  padding-left: 15px;
  border-left: 3px solid #009aa7;
}

#videos-2 .titles h2 span {
  display: inline-block;
  position: relative;
}

#videos-2 .titles h2 span:before {
  content: '';
  width: 100vw;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  left: calc(100% + 15px);
  top: 50%;
}

#videos-2 .titles p {
  color: #777;
  padding-left: 18px;
}

#videos-2 .card-video {
  text-align: center;
  width: 100%;
  max-width: 370px;
  margin-right: auto;
  margin-left: auto;
}

#videos-2 .card-video .card-title {
  font-weight: bold;
  font-size: 17px;
}

#videos-2 .card-video .card-title:after {
  content: '';
  display: block;
  width: 60%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 15px auto;
}

#videos-2 .card-video .btn-card {
  margin-top: 10px;
}

.modal-videos .embed-responsive {
  padding-bottom: 56.25%;
}

.modal-videos .modal-content {
  border: none;
  box-shadow: none;
  background: transparent;
}

.modal-videos .modal-header {
  border: none;
  padding-bottom: 10px;
  padding-top: 10px;
}

.modal-videos .modal-header .close {
  color: #FFF;
  font-size: 16px;
  opacity: 1;
}

.modal-videos .modal-body {
  padding: 0;
}

#contato3 {
  padding-bottom: 80px;
}

#contato3 .title-ordenal {
  padding-left: 3em;
  position: relative;
  color: #009aa7;
}

#contato3 .title-ordenal .order {
  display: block;
  font-size: 16px;
  color: #009aa7;
}

#contato3 .title-ordenal:before {
  content: '';
  display: block;
  width: 2em;
  height: 0.2em;
  background: currentcolor;
  position: absolute;
  left: 0;
  top: calc(16px * 1.4 + calc(1em * 1.4 * 0.4));
  transform: translateY(-50%);
}

#contato3 form {
  border-top: 5px solid #009aa7;
  padding-top: 40px;
  margin-bottom: 60px;
}

#contato3 .btn-enviar {
  display: inline-flex;
  align-items: center;
  border-radius: 0;
  color: #212529;
  background-color: #FFF;
  border-color: #FFF;
  color: #009aa7;
  border: none;
}

#contato3 .btn-enviar:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

#contato3 .btn-enviar:focus, #contato3 .btn-enviar.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

#contato3 .btn-enviar.disabled, #contato3 .btn-enviar:disabled {
  color: #212529;
  background-color: #FFF;
  border-color: #FFF;
}

#contato3 .btn-enviar:not(:disabled):not(.disabled):active, #contato3 .btn-enviar:not(:disabled):not(.disabled).active,
.show > #contato3 .btn-enviar.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

#contato3 .btn-enviar:not(:disabled):not(.disabled):active:focus, #contato3 .btn-enviar:not(:disabled):not(.disabled).active:focus,
.show > #contato3 .btn-enviar.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

#contato3 .btn-enviar:hover {
  color: #1b5488;
  background: #FFF;
}

@supports (-webkit-background-clip: text) {
  #contato3 .btn-enviar:hover {
    background: linear-gradient(166deg, #009aa7, #1b5488);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

#contato3 .btn-enviar:before {
  content: '';
  width: 1.5em;
  height: 0.25em;
  background: #009aa7;
  display: inline-block;
  margin-right: 7px;
}

#contato3 .dados-contato a {
  color: inherit;
}

#contato3 .dados-contato a:hover {
  color: #FFBF00;
}

#contato3 a:hover {
  color: #009aa7;
  text-decoration: none;
}

.funcionalidades__cards {
  display: flex;
}

.funcionalidades__cards .card {
  border-color: #282828;
  border-width: 2px;
  padding: 15px;
  display: block;
}

.funcionalidades__cards .card li ~ li {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .funcionalidades__cards .card {
    padding: 30px;
  }
}

@media (max-width: 767.98px) {
  .funcionalidades__cards .card {
    width: 100%;
    max-width: 280px;
    flex-shrink: 0;
    padding: 30px;
  }
  .funcionalidades__cards .card ~ .card {
    margin-left: 15px;
  }
}

@media (min-width: 768px) {
  .funcionalidades__cards {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 15px));
    grid-gap: 30px;
  }
}

@media (max-width: 767.98px) {
  .funcionalidades__cards {
    display: flex;
    overflow: auto;
    scrollbar-width: none;
  }
  .funcionalidades__cards::-webkit-scrollbar {
    display: none;
  }
  .funcionalidades__cards:before, .funcionalidades__cards:after {
    content: '';
    display: block;
    width: 15px;
    flex-shrink: 0;
  }
}

.plano__plugin {
  border: 2px solid #282828;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 25px;
}

@media (min-width: 768px) {
  .plano__plugin {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .plano__plugin.lit {
    max-width: 58.55856%;
  }
}

@media (max-width: 767.98px) {
  .plano__plugin {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, auto);
    text-align: center;
  }
  .plano__plugin .plano__plugin__column:nth-child(1) {
    grid-column: 1/3;
    grid-row: 1/2;
    border-bottom: 2px solid #282828;
  }
  .plano__plugin .plano__plugin__column:nth-child(2) {
    border-right: 1px solid #282828;
  }
  .plano__plugin .plano__plugin__column:nth-child(3) {
    border-left: 1px solid #282828;
  }
  .plano__plugin.lit .plano__plugin__column {
    grid-column: 1/3;
  }
  .plano__plugin.lit .plano__plugin__column:nth-child(2) {
    border: none;
  }
}

.plano__plugin__column {
  padding: 15px;
}

.planos__plugin__container {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .planos__plugin__container {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  .planos__plugin__container {
    max-width: 1140px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .planos__plugin__container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .planos__plugin__container {
    max-width: 1140px;
  }
}

@media (max-width: 767.98px) {
  .planos__plugin__container {
    display: flex;
    overflow: auto;
    width: 100%;
    scrollbar-width: none;
  }
  .planos__plugin__container::-webkit-scrollbar {
    display: none;
  }
  .planos__plugin__container:before, .planos__plugin__container:after {
    content: '';
    width: 15px;
    display: block;
    flex-shrink: 0;
  }
  .planos__plugin__container .plano__plugin {
    width: 250px;
    flex-shrink: 0;
  }
  .planos__plugin__container .plano__plugin ~ .plano__plugin {
    margin-left: 15px;
  }
}

.facilidade__comodidade {
  padding-top: 60px;
  padding-bottom: 60px;
  color: #FFF;
  background-color: #1a517c;
  background-image: url(../images/textura.png);
}

.facilidade__comodidade h2 {
  text-align: center;
  font-family: 'montserratmedium';
  margin-bottom: 25px;
}

.facilidade__comodidade h2 span {
  font-family: 'montserratextrabold';
}

.facilidade__comodidade .embed-responsive {
  margin-bottom: 30px;
}

.arquivos__download {
  padding: 60px 0;
}

.arquivos__download h2 {
  color: #009aa7;
  text-align: center;
  font-family: 'montserratmedium';
  margin-bottom: 20px;
}

.arquivos__download .btn {
  padding: .75rem 1.5rem;
}

.file__download {
  border: 2px solid #282828;
  border-radius: 6px;
  padding: 15px;
}

@media (max-width: 575.98px) {
  .file__download {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .file__download .file__download__link {
    margin-top: 10px;
  }
}

@media (min-width: 576px) {
  .file__download {
    display: grid;
    grid-template-columns: calc(100% - 80px) 80px;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 'TITLE TITLE' 'DESC  LINK';
  }
  .file__download .file__download__title {
    grid-area: TITLE;
  }
  .file__download .file__download__description {
    grid-area: DESC;
  }
  .file__download .file__download__link {
    grid-area: LINK;
    margin: auto;
  }
}

.file__download__title {
  color: #009aa7;
  font-size: 16px;
  font-family: 'montserratblack';
}

.file__download__description {
  font-size: 15px;
  line-height: 1.5;
  max-height: 4.5em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.file__download__link {
  display: flex;
  width: 55px;
  height: 55px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #009aa7;
  color: #FFF;
  box-shadow: 0 0 0 5px rgba(0, 154, 167, 0.5);
  border-radius: 50px;
}

.file__download__link:hover {
  color: #FFF;
}

.faixa-topo{
	.svg-icon{
		font-size: toEm(20,14);
	}

	.boas-vindas{
		font-family: 'montserratmedium';
	}

	.contatos{
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		white-space: nowrap;

		li{
			display: flex;
			align-items: center;

			svg{
				margin-right: 5px;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.boas-vindas {
			padding: 10px 15px;
			border-bottom:1px solid rgba(#fff,0.2);
		}

		.contatos{
			li{
				padding: 5px 10px;

				a{
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		font-size: 14px;
		background: $aqua;
		color: #FFF;

		.content{
			display: flex;
			justify-content: space-between;
		}

		.boas-vindas{
			padding-top: 5px;
			padding-bottom: 5px;
		}

		.contatos{
			display: flex;
			padding-top: 5px;
			padding-bottom: 5px;

			li{
				padding-left:10px;

				&:not(:last-child){
					padding-right:10px;
				}
			}

			li + li{
				border-left: 1px solid rgba(#fff,0.2);
			}
		}
	}
}

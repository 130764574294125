lazy-image{
	display: inline-block;
	width: 100%;
	max-width: calc(var(--w) * 1px);
	max-height: calc(var(--h) * 1px);
	position: relative;
	overflow: hidden;

	&:before{
		content:'';
		padding-bottom: calc( (var(--h) / var(--w)) * 100%);
		display: block;
	}

	&:not([loaded]),
	&.clone:not(.clone-loaded){
		background: #ddd;

		&:after{
			content:'';
			width: 30px;
			height: 30px;
			border:3px solid $aqua;
			border-radius: 50%;
			border-left-color: transparent;
			position: absolute;
			left: calc(50% - 15px);
			top: calc(50% - 15px);
			animation: lazyImageLoading 1s linear infinite;
		}

		img{
			opacity: 0;
		}
	}

	img{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		transition: opacity 0.4s linear;
	}
}


@keyframes lazyImageLoading{
	0%{
		transform: rotate(0deg);
	}

	100%{
		transform: rotate(359deg);
	}
}

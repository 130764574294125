#videos-2{

	.titles{
		overflow: hidden;
		margin-bottom: 60px;

		h2 {
			padding-left: 15px;
			border-left:3px solid $aqua;

			span{
				display: inline-block;
				position: relative;

				&:before{
					content:'';
					width: 100vw;
					border-top:1px solid rgba(#000,.2);
					position: absolute;
					left: calc(100% + 15px);
					top:50%;
				}
			}
		}

		p{
			color: #777;
			padding-left: 18px;
		}
	}

	.card-video{
		text-align:center;
		width: 100%;
		max-width: 370px;
		margin-right:auto;
		margin-left:auto;

		.card-title{
			font-weight: bold;
			font-size: 17px;

			&:after{
				content: '';
				display: block;
				width: 60%;
				border:1px solid rgba(#000,0.1);
				margin:15px auto;
			}
		}

		.btn-card{
			@extend .btn-gradient-deepaqua, .btn-expanded;
			margin-top: 10px;
		}
	}
}

.modal-videos{
	.embed-responsive{
		padding-bottom: percentage(9/16);
	}

	.modal-content{
		border: none;
		box-shadow: none;
		background: transparent;
	}

	.modal-header{
		border: none;
		padding-bottom: 10px;
		padding-top: 10px;

		.close{
			color:#FFF;
			font-size: 16px;
			opacity: 1;
		}
	}

	.modal-body{
		padding:0;
	}
}

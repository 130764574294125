@import "font-faces";

body{
	font-family: 'montserratregular', sans-serif;
}

a{
	color: inherit;
}

@include text-emphasis-variant('.text-aqua', $aqua);
@include text-emphasis-variant('.text-marinho', $marinho);

.subtitle{
	font-family: 'montserratsemibold';
	margin-bottom: 20px;
	font-size: 20px
}

.content__editable{
	@include clearfix;
	font-size: 15px;
	color: #282828;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6{
		font-family: 'montserratsemibold';
	}

	img{
		max-width: 100%;
		height: auto !important;
	}
}

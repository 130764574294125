@font-face {
	font-family: 'montserratmedium';
	font-display: auto;
	src: url('#{$fonts}/montserrat-medium/montserrat-medium-webfont.eot');
	src: url('#{$fonts}/montserrat-medium/montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
		 url('#{$fonts}/montserrat-medium/montserrat-medium-webfont.woff2') format('woff2'),
		 url('#{$fonts}/montserrat-medium/montserrat-medium-webfont.woff') format('woff'),
		 url('#{$fonts}/montserrat-medium/montserrat-medium-webfont.ttf') format('truetype'),
		 url('#{$fonts}/montserrat-medium/montserrat-medium-webfont.svg#montserratmedium') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'montserratsemibold';
    font-display: auto;
    src: url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.eot');
    src: url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.svg#montserratsemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratregular';
    font-display: auto;
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot');
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratextrabold';
    font-display: auto;
    src: url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.svg#montserratextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratblack';
    font-display: auto;
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot');
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;

}

#banner{
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;

	.carousel-inner{
		position: relative;
		z-index: 0;
	}

	lazy-image{
		display: block;
	}

	.carousel-indicators{
		li{
			width: 14px;
			height: 14px;
			border: 2px solid $aqua;
			border-radius: 50%;
			background: transparent;
			opacity: 1;

			&.active{
				background: $aqua;
			}
		}
	}

	.ctrls{
		position: absolute;
		z-index: 2;
		top:50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		max-width: 1140px;
		padding-left:15px;
		padding-left:15px;

		a{
			color: $aqua;
			width: 2em;
			height: 2em;
			border:2px solid $aqua;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content:center;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			transition: all 0.3s linear;

			&:hover{
				text-decoration: none;
				background: $aqua;
				color: #FFF;
			}

			&.prev{
				left: 15px;
			}

			&.next{
				right: 15px;
			}
		}
	}
}

.solucoes-index{
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 40px;

	.title{
		// font-family: 'montserratmedium', sans-serif;
		font-size: 36px;
		color: #282828;
		margin-bottom: 30px;

		span{
			font-family: 'montserratextrabold', sans-serif;
			color: $aqua;
		}
	}

	.subtitle{
		font-size: 20px;
		span{
			font-family: 'montserratextrabold', sans-serif;
		}
	}

	@include media-breakpoint-up(xl) {
		min-height: 915px;
		background-image: var(--lazyBG);
		background-position: calc(50% + 367px) bottom;
		background-repeat: no-repeat;
	}

	@include media-breakpoint-down(sm) {
		text-align: center;

		&:after{
			content: '';
			display: block;
			max-width: 875px;
			padding-bottom: percentage(875 / 735);
			background-image: var(--lazyBG);
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: calc(100% - 30px) auto;
		}
	}

	@include media-breakpoint-between(md,lg) {
		&:after{
			content: '';
			display: block;
			width: 735px;
			height: 875px;
			background-image: var(--lazyBG);
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: calc(100% - 30px) 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.servico-index{
	font-size: 14px;
	text-align: center;
	margin-bottom: 30px;

	.icon-area{
		font-size: 64px;
		margin-bottom: 30px;
		transition: color 0.3s linear;

		svg{
			display: block;
			margin: auto;
		}

		circle{
			fill:$aqua;
			transition: fill 0.3s linear;
		}
	}

	.nome-servico{
		font-size: 15px;
		font-family: 'montserratsemibold', sans-serif;
		transition: color 0.3s linear;
	}

	.descricao{
		line-height: 1.5em;
		max-height: 3em;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.ellipsis-h{
		width: 9px;
		height: 9px;
		border-radius: 50%;
		color: #d1d1d1;
		background: currentcolor;
		position: relative;
		margin-right: auto;
		margin-left: auto;
		margin-top: 15px;
		transition: color 0.3s linear;

		&:after,
		&:before{
			content:'';
			position: absolute;
			width: 100%;
			height:100%;
			border-radius:50%;
			top:0;
			background: currentcolor;
		}

		&:before{
			right: 200%;
		}

		&:after{
			left: 200%;
		}
	}

	a:hover{
		text-decoration: none;
		color: inherit;

		.icon-area{
			color: $aqua;

			circle{
				fill: $marinho;
			}
		}

		.nome-servico,
		.ellipsis-h{
			color: $aqua;
		}
	}
}

.planos{
	background: #282828;
	color: #FFF;
	padding-top: 45px;
	padding-bottom: 45px;

	.h1{
		font-family: 'montserratregular', sans-serif;
		font-size: 30px;
		text-align: center;
		margin-bottom: 1em;

		span{
			color: #009aa7;
			font-family: 'montserratextrabold', sans-serif;
		}
	}

	.plano__comparacao{
		@include media-breakpoint-up(sm) {
			display: flex;
		}
	}

	.plano__item{
		@include media-breakpoint-up(sm) {
			width: 50%;
			border-bottom: 1px solid #FFF;

			& + .plano__item{
				border-left: 1px solid #FFF;
			}
		}

		@include media-breakpoint-down(xs) {
			& ~ .plano__item{
				border-top: 1px solid #FFF;
			}
		}

		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 50px;
		padding-bottom: 80px;


		@include media-breakpoint-up(lg) {
			padding-left: 50px;
			padding-right: 50px;
		}

		@include media-breakpoint-down(md){
			padding-right: 15px;
			padding-left: 15px;
		}
	}

	.plano__header{
		padding: .75rem 1.5rem;
		border: 2px solid #FFF;
		border-radius: 7px;
		margin-bottom: 30px;
	}

	.plano__features{
		padding-left: 0;
		list-style: none;
		margin-bottom: 0;
		width: 100%;
		max-width: 425px;
		font-size: 15px;

		li{
			padding-left: 35px;
			min-height: 30px;
			position: relative;
			margin-bottom: 5px;
			padding-top: 3px;

			&:before{
				content:'';
				position: absolute;
				width: 25px;
				height: 25px;
				border:2px solid #FFF;
				left: 0;
				top: 2.5px;
				border-radius: 50%;
			}

			&.active::before{
				background: #FFF;
				box-shadow: 0 0 0 3px #282828 inset;
			}
		}
	}

	.fontes__disponiveis{
		@include media-breakpoint-up(sm) {
			display: grid;
			grid-template-columns: repeat(2, 50%);
			grid-template-rows: repeat(auto, auto);
		}

		@include media-breakpoint-down(xs) {
			display: flex;
			flex-direction: column;
			border-top:2px solid #FFF;
		}
	}

	.fontes__disponiveis__header{
		grid-column: 1/3;
		grid-row: 1/2;
		margin: auto;
		padding:.75rem 1.5rem;
		border:2px solid #FFF;
		border-radius: 4px;
		background: #282828;
		transform: translateY(-50%);
		position: relative;

		@include media-breakpoint-up(sm) {

			&:before{
				content:'';
				height: 100%;
				border-left: 1px solid #FFF;
				left: 50%;
				top: 100%;
				position: absolute;
			}
		}
	}

	.fontes__disponiveis__item{
		padding: 30px 20px;
		list-style: none;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

		@include media-breakpoint-up(sm) {

			& ~ .fontes__disponiveis__item{
				border-left: 1px solid #FFF;
			}
		}

		@include media-breakpoint-down(xs) {
			padding: 30px 0;

			& ~ .fontes__disponiveis__item{
				border-top: 1px solid #FFF;
			}
		}

		li{
			padding: 10px;
		}
	}
}

.nav-planos{
	list-style: none;
	padding-left: 0;
	margin-bottom: 30px;
	width: 100%;
	overflow: auto;
	display: flex;

	a{
		display: block;
		padding: 0.325rem 0.725rem;
		border: 1px solid #FFF;
		border-radius: 6px;
		font-family: 'montserratmedium', sans-serif;
		transition: all 0.3s linear;
		position: relative;
		overflow: hidden;
		z-index:1;
		text-align: center;

		&:before{
			content:'';
			position: absolute;
			width:0;
			height: 100%;
			background: #FFF;
			z-index:-2;
			transition:width 0.3s linear;
			left:0;
			bottom: 0;
		}

		&:hover{
			text-decoration: none;
			color: $cinza;

			&:before{
				width: 100%;
			}
		}
	}

	.active a{
		background: linear-gradient(166deg, $aqua, $marinho);
		border-color: transparent;

		&:hover{
			color: #FFF;
		}

		&:before{
			display: none;
		}
	}

	@include media-breakpoint-up(md) {
		justify-content: space-around;
	}

	@include media-breakpoint-down(sm) {
		justify-content: flex-start;

		a{
			white-space: nowrap;
		}

		li + li {
			margin-left: 10px;
		}
	}
}


.item-plano{
	& + .item-plano{
		border-top: 1px solid rgba(#FFF,.2);
	}
}

.planos-container{
	@include media-breakpoint-down(sm) {
		padding-left: 15px;
	}
}

.header-item-plano {
	display: flex;

	.item-name{
		padding: 10px 10px 10px 0;
		width: calc(100% - 24px);
		order: 1;
		font-family: 'montserratblack', sans-serif;
		color: $aqua;
		position: relative;
		transition: color 0.3s linear;

		&:before{
			content:'';
			width: 1em;
			height: 1em;
			border:2px solid $cinza;
			background: currentcolor;
			position: absolute;
			left: -15px;
			top:10px;
			transform: translate(-50%, 0.25em);
			border-radius: 50%;
		}
	}

	button{
		background: transparent;
		border:1px solid #FFF;
		color: #FFF;
		width: 2em;
		height: 2em;
		border-radius:50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		order: 2;
		align-self: center;
		margin-left: auto;
		outline: none;

		&[aria-expanded="true"] {
			.fa-plus:before{
				content:"\f068";
			}

			& ~ .item-name{
				color: $marinho;
			}
		}
	}
}

.section-parceiros{
	padding-top: 40px;

	h2{
		span{
			font-family: 'montserratextrabold', sans-serif;
			color: $aqua;
		}
	}
}

.owl-carousel#parceiros {
	.parceiro{
		padding: 8px;
		max-width: 160px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;

		&:hover {
			lazy-image{
				box-shadow:0 0 8px rgba(#000,.4);
			}
		}
	}

	padding-left: calc(2em + 8px);
	padding-right: calc(2em + 8px);

	.owl-prev,
	.owl-next{
		width: 2em;
		height: 2em;
		border-radius: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		outline: none;

		&:not(.disabled) {
			color: $aqua;
			border:2px solid $aqua;
		}

		&.disabled {
			color: #999;
			border:2px solid #999;
			cursor: default;
		}
	}

	.owl-prev{
		left: 0;
	}

	.owl-next{
		right: 0;
	}
}


.facilidades {
	padding-top: 60px;
	background-color: $marinho;
	background-image: var(--lazyBG);
	background-position: center center;
	position: relative;
	padding-bottom: 30px;
	min-height: 210px;
	z-index: 0;
	color: #FFF;

	h2.title{
		span{
			font-family: 'montserratsemibold', sans-serif;
			color: $aqua;
		}
	}

	&:after{
		content:'';
		display: block;
		position: absolute;
		width: 100%;
		height: 120px;
		background: $cinza;
		left:0;
		bottom: 0;
		z-index: 0;
	}

	.container{
		position: relative;
		z-index: 1;
	}

	.embed-responsive{
		margin-left: auto;
		margin-right: auto;
	}
}


.funcionalidades__cards{
	display: flex;

	.card{
		border-color: #282828;
		border-width: 2px;
		padding: 15px;
		display: block;

		li ~ li{
			margin-top: 10px;
		}

		@include media-breakpoint-up(md) {
			padding: 30px;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
			max-width: 280px;
			flex-shrink: 0;
			padding: 30px;

			& ~ .card{
				margin-left: 15px;
			}
		}
	}

	@include media-breakpoint-up(md) {
		display: grid;
		grid-template-columns: repeat(2, calc(50% - 15px));
		grid-gap: 30px;
	}

	@include media-breakpoint-down(sm) {
		display: flex;
		overflow: auto;
		scrollbar-width: none;

		&::-webkit-scrollbar{
			display: none;
		}

		&:before,
		&:after{
			content: '';
			display: block;
			width: 15px;
			flex-shrink: 0;
		}
	}
}

.plano__plugin{
	border: 2px solid #282828;
	margin-bottom: 10px;
	border-radius: 10px;
	font-size: 25px;

	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: space-between;
		width: 100%;

		&.lit{
			max-width: percentage(650/1110);
		}
	}

	@include media-breakpoint-down(sm) {
		display: grid;
		grid-template-columns: repeat(2,50%);
		grid-template-rows: repeat(2, auto);
		text-align: center;

		.plano__plugin__column{
			&:nth-child(1) {
				grid-column: 1/3;
				grid-row: 1/2;
				border-bottom: 2px solid #282828;
			}

			&:nth-child(2) {
				border-right: 1px solid #282828;
			}

			&:nth-child(3) {
				border-left: 1px solid #282828;
			}
		}

		&.lit{
			.plano__plugin__column{
				grid-column: 1/3;

				&:nth-child(2) {
					border: none;
				}
			}
		}
	}
}

.plano__plugin__column{
	padding: 15px;
}


.planos__plugin__container{
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;
	max-width: map-get($container-max-widths, xl);
	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-only(md) {
		max-width: map-get($container-max-widths, md);
	}

	@include media-breakpoint-up(xl) {
		max-width: map-get($container-max-widths, xl);
	}

	@include media-breakpoint-only(lg) {
		max-width: map-get($container-max-widths, lg);
	}

	@include media-breakpoint-up(xl) {
		max-width: map-get($container-max-widths, xl);
	}

	@include media-breakpoint-down(sm) {
		display: flex;
		overflow: auto;
		width: 100%;
		scrollbar-width: none;

		&::-webkit-scrollbar{
			display: none;
		}

		&:before,
		&:after{
			content:'';
			width: 15px;
			display: block;
			flex-shrink: 0;
		}

		.plano__plugin{
			width: 250px;
			flex-shrink: 0;

			& ~ .plano__plugin{
				margin-left: 15px;
			}
		}
	}
}

.facilidade__comodidade{
	padding-top: 60px;
	padding-bottom: 60px;
	color: #FFF;
	background-color: #1a517c;
	background-image: url(../images/textura.png);

	h2{
		text-align: center;
		font-family: 'montserratmedium';
		margin-bottom: 25px;

		span{
			font-family: 'montserratextrabold';
		}
	}

	.embed-responsive{
		margin-bottom: 30px;
	}
}

.arquivos__download{
	padding: 60px 0;

	h2{
		color: $aqua;
		text-align: center;
		font-family: 'montserratmedium';
		margin-bottom: 20px;
	}

	.btn{
		padding: .75rem 1.5rem;
	}
}

.file__download{
	border:2px solid #282828;
	border-radius: 6px;
	padding: 15px;

	@include media-breakpoint-down(xs) {
		display: flex;
		flex-direction: column;
		align-items: center;

		.file__download__link{
			margin-top: 10px;
		}
	}

	@include media-breakpoint-up(sm) {
		display: grid;
		grid-template-columns: calc(100% - 80px) 80px;
		grid-template-rows: repeat(2, auto);
		grid-template-areas:
			'TITLE TITLE'
			'DESC  LINK'
		;

		.file__download__title{
			grid-area: TITLE;
		}

		.file__download__description{
			grid-area: DESC;
		}

		.file__download__link{
			grid-area: LINK;
			margin: auto;
		}
	}
}

.file__download__title{
	color: $aqua;
	font-size: 16px;
	font-family: 'montserratblack';
}

.file__download__description{
	font-size: 15px;
	line-height: 1.5;
	max-height: 4.5em;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
}

.file__download__link{
	display: flex;
	width: 55px;
	height: 55px;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: $aqua;
	color: #FFF;
	box-shadow: 0 0 0 5px rgba($aqua,.5);
	border-radius: 50px;
	&:hover{
		color: #FFF;
	}
}

.internas{
	padding-bottom: 110px;
}

.page-header{
	background-color: $cinza;
	color: #FFF;
	background-image: var(--lazyBG);
	background-position: center top;
	background-repeat: no-repeat;
	text-align: center;
	margin-bottom: 125px;

	.filtro{
		min-height: 386px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		background: linear-gradient(to bottom, rgba($cinza,0.2) 0%, rgba($cinza, .9) calc(100% - 140px), #000 calc(100% - 20px));
		padding-bottom: 60px;
	}

	h1,
	h2{
		font-size: 35px;
	}

	h2{
		font-family: 'montserratblack';
	}

	.breadcrumb{
		padding:0 30px 15px 30px;
		min-width: 250px;
		max-width: 100%;
		background: none;
		display: inline-flex;
		justify-content:center;
		margin-bottom: 0;
		border-radius:0;
		border-bottom: 2px solid rgba(#000,0.2);
		position: relative;

		span{
			color: #888;
			cursor: default;
		}

		&:before{
			content:'';
			position: absolute;
			left:50%;
			bottom:-2px;
			transform: translateX(-50%);
			height: 5px;
			width: 60px;
			background: linear-gradient(166deg, $aqua, $marinho);
			border-radius: 5px;
		}

		li + li:before{
			content:'/';
			display: inline-block;
			margin:0 10px;
		}
	}
}

fieldset{
	margin-bottom: 40px;

	legend{
		h2{
			font-size: 20px;
			color: $aqua;
		}
	}
}

.form-control{
	border-color: $cinza;
	color: $cinza;
}

.md-input{
	position: relative;
	margin-top: 8px;

	label{
		display: block;
		margin-bottom: 0;
		padding: 0 0.375rem;
		background: #FFF;
		left: 0.375rem;
		top: 0.4rem;
		position: absolute;
		line-height: 1.5rem;
		transition:all 0.3s linear;
	}

	&.focus label,
	.form-control:focus ~ label,
	select.form-control ~ label{
		top: 0;
		transform: translateY(-50%);
		font-size: 12px;
	}

	&.has-success{
		.form-control{
			border-color: $success;
			color: $success;
		}

		label{
			color: $success;
		}
	}

	&.has-error{
		.form-control{
			border-color: $danger;
			color: $danger;
		}

		label{
			color: $danger;
		}
	}
}

.paginacao{
	margin-bottom: 60px;
	text-align:center;
	color: $cinza;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	& > *{
		display: inline-block;
		padding:0.3em 0.6em;
		border:2px solid $cinza;

		&:first-child{
			border-radius:4px 0 0 4px;
		}

		&:last-child{
			border-radius:0 4px 4px 0;
		}

		& + *{
			margin-left:-2px;
		}
	}

	.active{
		background: linear-gradient(166deg, $aqua, $marinho);
		border-color: rgba(#000,0.2);
		color: #FFF;
		position: relative;
		z-index: 2;
	}

	a:hover{
		background: rgba(#000,0.1);
		text-decoration: none;

		&:hover{
			background: $cinza;
			color: #FFF;
		}
	}
}

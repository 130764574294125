.rodape{
	background-color: $cinza;
	background-image: linear-gradient(to bottom, $cinza 0%, #100f10 calc(100% - 60px));
	color: #FFF;
	padding-top: 35px;
	padding-bottom: 15px;

	@include media-breakpoint-down(sm) {
		padding-bottom: 65px;
	}

	@include media-breakpoint-only(xs) {
		font-size: 14px;
	}

	.brand{
		max-width: 255px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		font-style: italic;
		font-family: 'montserratregular';
		margin-bottom: 30px;

		img{
			margin-bottom: 10px;
		}
	}

	a:hover{
		color: $aqua;
		text-decoration: none;
	}
}

.contatos-footer{
	svg{
		color: $aqua;
		font-size: 1.5em;
		margin-right: 10px;
	}

	.telefones {
		display: flex;

		ul{
			display: inline-block;
			list-style: none;
			padding-left:0;
			columns: 2;
			column-rule: 1px solid currentcolor;
			white-space: nowrap;
		}
	}

	.redes-sociais{
		@include media-breakpoint-down(md) {
			text-align: center;
		}

		@include media-breakpoint-up(lg) {
			display: flex;
			align-items: center;

			.title{
				white-space: nowrap;
				margin-right: 10px;
			}
		}

		a{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			width: 2em;
			height: 2em;
			border-radius: 50%;
			background: $aqua;
			color: $cinza;
			transition: background 0.3s linear;

			&:hover{
				background: #FFF;
				text-decoration: none;
				color: $cinza;
			}
		}
	}
}

.creditos{
	text-align: center;
	font-size: 10px;
}

.autor{
	text-align: center;
	font-size: 10px;
}

.btns-fixos{
	position: fixed;
	bottom: 0;
	z-index: 2000;
	width: 100%;
	max-width: 1140px;
	left: 50%;
	transform: translateX(-50%);

	.btn{
		position: absolute;
		overflow: hidden;
		bottom: 0;
		border-color: rgba(#000,0.3);

		&:before{
			content:'';
			width: 50%;
			height: 100%;
			left: -60%;
			top:0;
			background: linear-gradient(to right, transparent, #FFF, transparent);
			opacity: 0.6;
			position: absolute;
			transform: skew(-15deg);
			animation: shiningButton 5s linear infinite;
		}
	}
}

.btn-cadastre-se{
	font-family: 'montserratmedium';
	@include button-variant($marinho, $marinho);
	left: 15px;
	box-shadow: 0 0 8px rgba(#000,.4);

	@include media-breakpoint-up(lg) {
		border-radius: 10px 10px 0 0;
		bottom: 0;
		left: 15px;
	}

	@include media-breakpoint-down(md) {
		span{
			@include sr-only;
		}

		margin-bottom: 10px;
		padding: 0;
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}
}

.btn-teste{
	font-family: 'montserratmedium';
	@include button-variant($aqua, $aqua);
	right: 15px;
	box-shadow: 0 0 8px rgba(#000,.4);
	max-width: 300px;
	text-align: left;
	display: flex;

	svg{
		font-size: 37px;
	}

	@include media-breakpoint-up(lg) {
		border-radius: 10px 10px 0 0;
		bottom: 0;

		svg{
			margin-right: 15px;
			align-self: center;
		}
	}

	@include media-breakpoint-down(md) {
		span{
			@include sr-only;
		}

		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		margin-bottom: 10px;
		padding: 0;

		&:before{
			width: 80%;
		}

		svg{
			font-size: 20px;
		}
	}
}


@keyframes shiningButton{
	0%{
		left:-60%;
	}

	12%,100%{
		left: 100%;
	}
}

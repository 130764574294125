.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: calc(165px / 2) calc(108px / 2);

	&.clound-up{
		width: calc(60px / 2);
		height: calc(56px / 2);
		background-position: calc(0px / 2) calc(0px / 2);
	}

	&.detalhe-depoimento-3{
		width: calc(53px / 2);
		height: calc(42px / 2);
		background-position: calc(-70px / 2) calc(0px / 2);
	}

	&.favicon{
		width: calc(32px / 2);
		height: calc(32px / 2);
		background-position: calc(-133px / 2) calc(0px / 2);
	}

	&.megafone-agenda-1{
		width: calc(19px / 2);
		height: calc(18px / 2);
		background-position: calc(-133px / 2) calc(-83px / 2);
	}

	&.stardust{
		width: calc(31px / 2);
		height: calc(31px / 2);
		background-position: calc(-133px / 2) calc(-42px / 2);
	}

	&.telemarketing-sm{
		width: calc(33px / 2);
		height: calc(34px / 2);
		background-position: calc(-50px / 2) calc(-66px / 2);
	}

	&.telemarketing{
		width: calc(40px / 2);
		height: calc(42px / 2);
		background-position: calc(0px / 2) calc(-66px / 2);
	}

}


.mobile-controls{
	background: #282828;
	display: flex;
	justify-content: space-between;

	.brand{
		padding: 5px 10px;
		width: 215px;
	}

	@include media-breakpoint-up(md) {
		display: none;
	}
}

.btn-mobile{
	@include button-variant(#282828, #282828);
	display: flex;
	align-items: center;
	border-radius: 0;

	.bars{
		width: 30px;
		height: 5px;
		background: currentcolor;
		border-radius: 5px;
		position: relative;
		point-events: none;
		transition: background 0.3s linear;

		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 5px;
			left:0;
			transition: all 0.3s linear;
			background: currentcolor;
		}

		&:before{
			top: -10px;
		}

		&:after{
			top: 10px;
		}
	}

	&.active .bars{
		background: transparent;

		&:before,
		&:after{
			top:0;
		}

		&:before{
			transform: rotate(45deg);
		}

		&:after{
			transform: rotate(-45deg);
		}
	}
}

.menu-backdrop{
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(#000,0.6);
	left:0;
	top: 0;
	z-index: 999;
	display: none;
}

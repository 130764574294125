.svg-icon,
.svg-sprite{
	width: 1em;
	height: 1em;
	min-width: 1em;
	min-height: 1em;

	use{
		fill: currentcolor;
	}
}

.mgb-30{
	margin-bottom: 30px;
}

.mgb-40{
	margin-bottom: 40px;
}

.mgb-50{
	margin-bottom: 50px;
}

.mgb-60{
	margin-bottom: 60px;
}

.embed-responsive{
	width: 100%;
	max-width: calc(var(--width) * 1px);
	max-height: calc(var(--height) * 1px);

	&:before{
		content:'';
		display: block;
		padding-bottom: calc( (var(--height) / var(--width) ) * 100%);
	}

	&.lazy-embed{
		background: #DDD;

		&:not(.loaded) {
			&:after{
				content:'';
				width: 30px;
				height: 30px;
				border:3px solid $aqua;
				border-radius: 50%;
				border-left-color: transparent;
				position: absolute;
				left: calc(50% - 15px);
				top: calc(50% - 15px);
				animation: lazyEmbedLoading 1s linear infinite;
			}

			iframe{
				opacity: 0;
			}
		}

		&.loaded{
			iframe{
				opacity: 1;
			}
		}

		iframe{
			transition: opacity 1s linear;
		}
	}
}

@keyframes lazyEmbedLoading{
	0%{
		transform: rotate(0deg);
	}

	100%{
		transform: rotate(359deg);
	}
}


.bg-gradient-aqua{
	background: linear-gradient(166deg, $aqua, $marinho);
	color: #FFF;
}


.modal{
	.modal-content,
	.modal-body,
	.modal-footer,
	.modal-header{
		border-radius: 0;
	}
}
